/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Button from "../../extras/Button";
import Table from "../../extras/Table";
import Pagination from "../../extras/Pagination";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { closeDialog, openDialog } from "../../../redux/slice/dialogueSlice";
import Title from "../../extras/Title";
import { allUsers, blockUser } from "../../../redux/slice/userSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Searching from "../../extras/Searching";
import male from "../../../assets/images/male.png";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
// import ServiceDialogue from "./ServiceDialogue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import UserHistory from "./UserHistory";
import NotificationDialog from "./NotificationDialog";


export const User = () => {
  const dispatch = useDispatch();
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  ;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { user, total } = useSelector((state) => state?.user);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("ALL");
  const navigate = useNavigate();

  useEffect(() => {
    const payload = {
      start: page,
      limit: rowsPerPage,
      search,
    };
    dispatch(allUsers(payload));
  }, [dispatch,page, rowsPerPage, search]);

  useEffect(() => {
    setData(user);
  }, [user]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  function openImage(imageUrl) {
    // Open the image in a new tab or window
    window.open(imageUrl, "_blank");
  }

  const userTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Image",
      Cell: ({ row }) => (
        <div
          className="userProfile"
          style={{
            height: "70px",
            width: "70px",
            overflow: "hidden",
            borderRadius: "10px",
          }}
        >
          <img
            src={row?.image ? row?.image : male}
            alt=""
            className="cursor-pointer"
            onClick={() => handleInfo(row._id)}
            
          />
        </div>
      ),
    },
    {
      Header: "Name",
      Cell: ({ row }) => (
        <span
          className="text-capitalize fw-bold cursor"
          onClick={() => handleInfo(row._id)}
        >
          {row?.fname ? row?.fname + " " + row?.lname : "Salon User"}
        </span>
      ),
    },
    {
      Header: "Unique ID",
      Cell: ({ row }) => <span>{row?.uniqueId}</span>,
    },
    {
      Header: "Mobile No",
      Cell: ({ row }) => <span>{row?.mobile}</span>,
    },
    {
      Header: "Email",
      Cell: ({ row }) => (
        <span>{row?.email ? row?.email : "demo@demo.com"}</span>
      ),
    },
    {
      Header: "gender",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.gender ? row?.gender : "Not Specified"}
        </span>
      ),
    },
    {
      Header: "Block",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row?.isBlock}
          onClick={() => handleStatus(row?._id)}
        />
      ),
    },
    // {
    //     Header: "IsBlock",
    //     Cell: ({ row }) => (
    //         <ToggleSwitch value={row?.isBlock} onClick={() => handleStatus(row?._id)} />
    //     ),
    // },
    {
      Header: "Info",
      Cell: ({ row }) => (
        <span className="d-flex g-2">
          <button
            className="bg-theme rounded-1 text-light m5-right p10-x p4-y fs-12"
            onClick={() => handleInfo(row._id)}
          >
            Info
          </button>
          <button
            className="bg-primary rounded-1 text-light m5-right p10-x p4-y fs-12"
            onClick={() => handleProfile(row, "userHistory")}
          >
            History
          </button>
        </span>
      ),
    },
    {
      Header: "Notification",
      Cell: ({ row }) => (
        <span className="d-flex g-2">
          <button
            className="bg-theme rounded-1 text-light fs-13 m5-right p20-x p4-y fs-12"
            onClick={() => handleNotify(row?._id)}
          >
            <i class="fa-regular fa-bell" />
          </button>
        </span>
      ),
    },
  ];

  const handleInfo = (id) => {
   
    navigate("/admin/user/userProfile", {
      state: {
        id,
      },
    });
  };

  const handleProfile = (row, type) => {
   
    dispatch(openDialog({ type: type, data: row }));
    localStorage.setItem(
      "dialog",
      JSON.stringify({ dialogue: true, type: type, data: row })
    );
  };
  const handleNotify = (id) => {
   

    dispatch(openDialog({ type: "notification", data: { id, type: "user" } }));
  };

  const handleStatus = (id) => {
    
   

    dispatch(blockUser(id));
  };

  const handleDownloadPDF = () => {
    const flattenedData = data.map((user, index) => ({
      No: index + 1,
      Name: user?.fname + " " + user?.lname,
      UniqueId: user?.uniqueId,
      MobileNo: user?.mobile,
      Email: user?.email,
    }));

    const headers = ["No", "Name", "UniqueId", "MobileNo", "Email"];
    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.text("User Data", 10, 10);
    doc.autoTable({
      head: [headers],
      body: flattenedData?.map((user) => Object?.values(user)),
    });
    doc.save("user_data.pdf");
  };

  const handleDownloadCSV = () => {
    const flattenedData = data.map((user, index) => ({
      No: index + 1,
      Name: user?.fname + " " + user?.lname,
      UniqueId: user?.uniqueId,
      MobileNo: user?.mobile,
      Email: user?.email,
    }));

    const headers = ["No", "Name", "UniqueId", "MobileNo", "Email"];
    const csvContent = Papa.unparse({
      fields: headers,
      data: flattenedData,
    });
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL?.createObjectURL(blob);
    const link = document?.createElement("a");
    link.href = url;
    link.setAttribute("download", "user_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadExcel = () => {
    const flattenedData = data?.map((user, index) => ({
      No: index + 1,
      Name: user?.fname + " " + user?.lname,
      UniqueId: user?.uniqueId,
      MobileNo: user?.mobile,
      Email: user?.email,
    }));

    const headers = ["No", "Name", "UniqueId", "MobileNo", "Email"];
    const ws = XLSX.utils.json_to_sheet([headers, ...flattenedData]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "User Data");
    XLSX.writeFile(wb, "user_data.xlsx");
  };

  const handlePrint = () => {
    const flattenedData = data?.map((user, index) => ({
      No: index + 1,
      Name: user?.fname + " " + user?.lname,
      UniqueId: user?.uniqueId,
      MobileNo: user?.mobile,
      Email: user?.email,
    }));

    const headers = ["No", "Name", "UniqueId", "MobileNo", "Email"];
    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.text("User Data", 10, 10);
    doc.autoTable({
      head: [headers],
      body: flattenedData.map((user) => Object.values(user)),
    });
    doc.autoPrint();
    window.open(doc.output("bloburl"), "_blank");
  };

  return (
    <div className="mainCategory">
      {dialogue && dialogueType === "userHistory" && (
        <div className="userTable">
          <UserHistory />
        </div>
      )}
      {dialogue && dialogueType === "notification" && (
        <div className="userTable">
          <NotificationDialog />
        </div>
      )}
      <div
        className="userTable"
        style={{
          display: `${dialogueType === "userHistory" ? "none" : "block"}`,
        }}
      >
        <Title name="Customer" />
        <div className="betBox">
          <div className="d-flex justify-content-start  gap-2">
            <Button
              className="bg-secondary text-white p5-y"
              onClick={handleDownloadPDF}
              text={`PDF`}
            />
            <Button
              className="bg-info text-white p5-y"
              onClick={handleDownloadCSV}
              text={`CSV`}
            />
            <Button
              className="bg-primary text-white p5-y"
              onClick={handleDownloadExcel}
              text={`Excel`}
            />
            <Button
              className="bg-danger text-white p5-y"
              onClick={handlePrint}
              text={`Print`}
            />
          </div>
          <div className="col-md-8 col-lg-5  ms-auto">
            <Searching
              type={`server`}
              data={user}
              setData={setData}
              column={userTable}
              serverSearching={handleFilterData}
            />
          </div>
        </div>
        <div>
          <Table
            data={data}
            mapData={userTable}
            serverPerPage={rowsPerPage}
            // PerPage={rowsPerPage}
            Page={page}
            // serverSearching={handleFilterData}
            // type={"server"}
          />
          <Pagination
            type={"server"}
            serverPage={page}
            setServerPage={setPage}
            serverPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            totalData={total}
          />
        </div>
        {/* {
          dialogue && dialogueType === "service" && (
              <ServiceDialogue setData={setData} data={data} />
          )
      } */}
      </div>
    </div>
  );
};
