import Table from "../../extras/Table";
import Button from "../../extras/Button";
import React, { useEffect, useState } from "react";
import Title from "../../extras/Title";
import { useDispatch, useSelector } from "react-redux";
import { getSalonTime, timeActive } from "../../../redux/slice/timeSlice";
import TimeDialogue from "./TimeDialogue";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import ToggleSwitch from "../../extras/ToggleSwitch";


const WeekTime = () => {
  const { time } = useSelector((state) => state?.time);
  const { dialogue, dialogueType } = useSelector((state) => state?.dialogue);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  ;

  useEffect(() => {
    dispatch(getSalonTime());
  }, []);

  useEffect(() => {
    setData(time);
  }, [time]);

  const timeTable = [
    {
      Header: "No",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    {
      Header: "Day",
      Cell: ({ row }) => <span className="text-capitalize">{row?.day}</span>,
    },
    {
      Header: "Open Time",
      Cell: ({ row }) => <span>{row?.openTime}</span>,
    },
    {
      Header: "Close Time",
      Cell: ({ row }) => <span>{row?.closedTime}</span>,
    },
    {
      Header: "Salon Break Time",
      Cell: ({ row }) => <span>{row?.breakTime}</span>,
    },
    // {
    //     Header:"Slot Time",
    //     Cell:({row}) =>(
    //         <span>{row?.slot + " Minute"}</span>
    //     )
    // },
    // {
    //     Header: "Status",
    //     Cell: ({ row }) => (
    //         <ToggleSwitch value={row?.isActive} onClick={() => handleStatus(row?._id)} />
    //     ),
    // },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <button
            className="bg-success text-light m5-right p10-x p4-y fs-12 br-5"
            onClick={() =>
              dispatch(openDialog({ type: "timeTable", data: row }))
            }
          >
            Edit
          </button>
        </span>
      ),
    },
  ];

  const handleStatus = (id) => {
    
    dispatch(timeActive(id));
  };
  return (
    <div className="mainTimeTable">
      <Title name="Saloon Time" />
      {/* <Button
        className={`bg-theme p-10 text-white m10-bottom`}
        text={`Add`}
        bIcon={`fa-solid fa-user-plus`}
        onClick={() => {
            dispatch(openDialog({ type: "timeTable" }));
        }}
    /> */}
      <div>
        <Table data={data} mapData={timeTable} />
      </div>
      {dialogue && dialogueType === "timeTable" && (
        <TimeDialogue setData={setData} data={data} />
      )}
    </div>
  );
};
export default WeekTime;
