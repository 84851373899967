/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */

import { openDialog } from "../../redux/slice/dialogueSlice";
import { getPayout } from "../../redux/slice/payoutSlice";
import { warning } from "../utils/Alert";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Title from "../extras/Title";
import Button from "../extras/Button";
import Table from "../extras/Table";
import Pagination from "../extras/Pagination";
import Analytics from "../extras/Analytics";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const StaffEarning = () => {
  const { payout, total } = useSelector((state) => state.payout);
  const { setting } = useSelector((state) => state.setting);

  const [data, setData] = useState([]);
  const startOfMonth = moment().startOf("month").toDate();
  const endOfMonth = moment().endOf("month").toDate();
  const dDate = moment(startOfMonth).format("YYYY-MM-DD");
  const d2Date = moment(endOfMonth).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(dDate);
  const [endDate, setEndDate] = useState(d2Date);
  const navigate = useNavigate();
  useEffect(() => {
    const payload = {
      startDate,
      endDate,
    };
    dispatch(getPayout(payload));
  }, [startDate, endDate]);

  useEffect(() => {
    setData(payout);
  }, [payout]);

  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleInfo = (id) => {
    navigate("/admin/expert/income", {
      state: {
        id,
      },
    });
  };

  const mapData = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Image",
      Cell: ({ row }) => (
        <div
          className="userProfile"
          style={{ height: "70px", width: "70px", overflow: "hidden" }}
        >
          <img
            src={row?.Expert?.image}
            alt="image"
            className="cursor-pointer"
            onClick={() => handleInfo(row?.Expert?._id)}
            height={`100%`}
            
          />
        </div>
      ),
    },
    {
      Header: "Name",
      Cell: ({ row }) => (
        <span
          className="text-capitalize fw-bold cursor"
          onClick={() => handleInfo(row?.Expert?._id)}
        >
          {row?.Expert?.fname + " " + row?.Expert?.lname}
        </span>
      ),
    },
    {
      Header: "Total Bookings",
      sorting: { type: "client" },
      body: "totalBookings",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.expertEarning == 0 ? 0 : row?.totalBookings}
        </span>
      ),
    },

    {
      Header: "Staff Earning",
      sorting: { type: "client" },
      body: "expertEarning",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.expertEarning?.toFixed(2) + " " + setting?.currencySymbol}
        </span>
      ),
    },
    {
      Header: "Admin Earning",
      sorting: { type: "client" },
      body: "adminEarning",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.adminEarning?.toFixed(2) + " " + setting?.currencySymbol}
        </span>
      ),
    },
    {
      Header: "Admin Commission",
      body: "expert.commission",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.Expert?.commission + " " + "%"}
        </span>
      ),
    },
    // {
    //     Header: "Bonus/Penalty",
    //     Cell: ({ row }) => (
    //         <span className="text-capitalize">{row?.name}</span>
    //     ),
    // },
    {
        Header: "Action",
        Cell: ({ row }) => (
            <span>
                <button
                    className='bg-success text-light m5-right p10-x p4-y fs-12 br-5'
                    onClick={() => handleEarning(row?.Expert?._id)}
                >Earnings</button>
            </span>
        ),
        width: "300px"
    }
    ];
    const handleEarning = (id) => {
        navigate("/admin/expert/income",{
          state : {
            id
          }
        })
      }

  function openImage(imageUrl) {
    // Open the image in a new tab or window
    window.open(imageUrl, "_blank");
  }

  return (
    <div className="mainCategory">
      <Title name="Expert Earnings" />
      <div className="row ">
        <div className="mt-2 col-md-9">
          <Analytics
            analyticsStartDate={startDate}
            analyticsStartEnd={endDate}
            analyticsStartDateSet={setStartDate}
            analyticsStartEndSet={setEndDate}
            allAllow={false}
          />
        </div>
      </div>

      <div>
        <Table
          data={data}
          mapData={mapData}
          PerPage={rowsPerPage}
          Page={page}
          type={"client"}
          // onChildValue={handleChildValue}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={data?.length}
        />
      </div>
    </div>
  );
};

export default StaffEarning;
