import React from 'react';
import { useNavigate } from 'react-router-dom';

const Title = (props) => {
  const navigate = useNavigate()
  const { name } = props;

  const handleDashboardClick = () => {
    navigate("/admin/adminDashboard");
  };
  return (
    <div className='mainTitle d-flex align-items-center justify-content-between cursor-pointer'>
      <div className="title text-uppercase fw-600 ">{name}</div>
      <div className="titlePath">
        <span  onClick={handleDashboardClick}>Dashboard  <i className="ri-arrow-right-s-line"></i></span>
        <span className='text-second'> {name}</span>
      </div>
    </div>
  );
}

export default Title;
