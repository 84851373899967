/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Title from "../../extras/Title";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { blockUser, getUser } from "../../../redux/slice/userSlice";
import { useState } from "react";
import Female from "../../../assets/images/lum3n-ck3HFWw2OiM-unsplash.jpg";
import { ExInput } from "../../extras/Input";
import ToggleSwitch from "../../extras/ToggleSwitch";
import Button from "../../extras/Button";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Male from "../../../assets/images/male.png";

const UserProfile = () => {
  const { user } = useSelector((state) => state.user);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUser(state?.id));
  }, [state]);

  useEffect(() => {
    setData(user);
  }, [user]);

  const handelPreviousPage = () => {
    if (state) {
      navigate(-1);
    } else {
      localStorage.removeItem("dialogueData");
      dispatch({ type: closeDialog });
    }
  };
  return (
    <div className="userProfile focusNone">
      <Title name={`${data?.fname ? data?.fname + ` ` + data?.lname : 'User'}'s   Profile`} />
      <div className="col-7 my-auto ms-auto justify-content-end d-flex pe-3">
      <Button
            className={`bg-danger  text-center text-white mt-3`}
            onClick={handelPreviousPage}
            style={{
              borderRadius: "5px",
              // height: "36px",
              // width: "50px",
            }}
            bIcon={`fa-solid fa-angles-left text-white fs-20 m-auto`}
            text='Back'
          />
        </div>
      <div className="boxShadow p-4">
        <div
          className="position-relative  rounded-4 px-4 w-100"
          style={{ height: "180px", backgroundColor: "#0F7085" }}
        >
          <div className="bg-theme w-100">
            <img
              src={data?.image ? data.image : Male}
              onError={(e) => {
                e.target.src = Male; 
              }}
              alt="User"
              className="mx-auto  position-absolute"
              style={{
                width: 160,
                height: 160,
                objectFit: "cover",
                borderRadius: "50%",
                top: "88px",
                left: "50px",
                backgroundColor: "#FFFFFF",
                padding: "5px",
              }}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: "100px" }}>
          <div className="col-xl-4 col-md-6 col-sm-12">
            <ExInput
              type={`text`}
              value={data?.fname}
              label={`First Name`}
              readOnly={true}
            />
          </div>
          <div className="col-xl-4 col-md-6 col-sm-12">
            <ExInput
              type={`text`}
              value={data?.lname}
              label={`Last Name`}
              readOnly={true}
            />
          </div>
          <div className="col-xl-4 col-md-6 col-sm-12">
            <ExInput
              type={`text`}
              value={data?.uniqueId}
              label={`Unique Id`}
              readOnly={true}
            />
          </div>
          <div className="col-xl-4 col-md-6 col-sm-12">
            <ExInput
              type={`text`}
              value={data?.mobile}
              label={`Mobile Number`}
              readOnly={true}
            />
          </div>
          <div className="col-xl-4 col-md-6 col-sm-12">
            <ExInput
              type={`text`}
              value={data?.gender}
              label={`Gender`}
              readOnly={true}
            />
          </div>
          <div className="col-xl-4 col-md-6 col-sm-12">
            <ExInput
              type={`text`}
              value={data?.age}
              label={`Age`}
              readOnly={true}
            />
          </div>
          <div className="col-xl-4 col-md-6 col-sm-12">
            <ExInput
              type={`text`}
              value={data?.email}
              label={`Email Id`}
              readOnly={true}
            />
          </div>
          <div className="col-xl-4 col-md-6 col-sm-12">
            <ExInput
              type={`text`}
              value={data?.loginType == 1 ? "Email Login" :data?.loginType == 2 ? "Google Login" : "Mobile Login"}
              label={`Login Type`}
              readOnly={true}
              placeholder={`Login Type`}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 inputData">
            <label>Bio</label>
            <textarea
              value={data?.bio}
              readOnly
              style={{ width: "100%",resize: "none" }}
              cols="30"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

