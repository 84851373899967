import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Navigator from "../extras/Navigator";
import $ from "jquery";
import { useEffect, useState } from "react";
import { logout } from "../../redux/slice/authSlice";
import { warning } from "../utils/Alert";
import { closeDialog } from "../../redux/slice/dialogueSlice";
import logo from "../../assets/images/logo2.png";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const data = warning("Logout");
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed
        console.log("yes", yes);
        if (yes) {
          dispatch(logout())
          navigate("/");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleOnClick = () => {
    localStorage.removeItem("dialog")  
    dispatch(closeDialog())
  }
  
  const navBarArray = [
    {
      name: "Dashboard",
      path: "/admin/adminDashboard",
      navIcon: "ri-dashboard-2-line",
    },
    {
      name: "Banner",
      path: "/admin/bannerTable",
      navIcon: "ri-flag-line",

    },
    
  ];

  const array2 = [
    {
      name: "Expert",
      path: "/admin/allExperts",
      navIcon: "bi bi-person",
      onClick: handleOnClick,
    },
    {
      name: "Customer",
      path: "/admin/userTable",
      navIcon: "bi bi-people",
      onClick: handleOnClick,
    },
    {
      name: "Reviews",
      path: "/admin/reviewTable",
      navIcon: "bi bi-star",
      onClick: handleOnClick,
    },
    {
      name: "Complain",
      path: "/admin/complainTable",
      navIcon: "bi bi-ticket",
      onClick: handleOnClick,
    },
    {
      name: "Expert attendance",
      path: "/admin/attendanceTable",
      navIcon: "bi bi-person-check",
    },
  ];
  const array6 = [
    {
      name: "Bookings",
      navIcon: "bi bi-calendar3",
      subMenu: [
        {
          subName: "All Bookings",
          subPath: "/admin/bookingTable",
        },
        {
          subName: "Daily Bookings",
          subPath: "/admin/dailyBookingTable",
        },
        {
          subName: "Monthly Report",
          subPath: "/admin/monthlyReport",
        },
      ],
      
    },
    {
      name: "Calender",
      path: "/admin/calendar",
      navIcon: "bi bi-calendar3",
    },
  ]
  const array3 = [
    {
      name: "Category",
      path: "/admin/categoryTable",
      navIcon: "ri-shopping-bag-3-line",

    },
    {
      name: "Services",
      path: "/admin/serviceTable",
      navIcon: "ri-wallet-3-line",
    },
  ];
  const array4 = [
    {
      name: "Expert-earnings",
      path: "/admin/staffEarning",
      navIcon: "bi bi-currency-dollar",
      onClick: handleOnClick,
    },
    {
      name: "Expert Payment",
      path: "/admin/payout",
      navIcon: "bi bi-bank",
      onClick: handleOnClick,
    },
   
  ];
  const array5 = [
    
   
    {
      name: "Salon Reports",
      navIcon: "ri-money-dollar-box-line",
      onClick: handleOnClick,
      subMenu: [
        {
          subName: "Payment History",
          subPath: "/admin/yearlyPayment",
        }
      ],
    },
    {
      name: "Saloon Time",
      navIcon: "fa-regular fa-calendar-check",
      path: '/admin/timeTable'
    },
    {
      name: "Saloon Holiday",
      navIcon: "bi bi-cup-hot",
      path: '/admin/holiday'
    },
    {
      name: "Setting",
      path: "/admin/setting",
      navIcon: "ri-settings-2-line",
    },

    {
      name: "LogOut",
      navIcon: "ri-logout-circle-r-line",
      onClick: handleLogout,
    },
  ];

  var webSize = $(window).width();
  
  return (
    <div className="mainSidebar">
      <SideMenuJS />
      <div className="sideBar">
        <div className="sideBarLogo">
          <div className="logo d-flex justify-content-center">
            {/* <img src={Logo} alt="logo" /> */}
              <img src={logo} alt="" className=" cursor"  onClick={() => navigate('/admin/adminDashboard')} /> 
            <h3 className="cursor text-nowrap mb-0 ms-3" onClick={() => navigate('/admin/adminDashboard')}>Salon Slot 
            </h3>
          </div>
          {/* <div className="smallLogo">
            <img src={""} alt="logo" className="smallLogo" />
          </div> */}
          <i className="ri-close-line closeIcon navToggle"></i>
          <div className="blackBox navToggle"></div>
        </div>
        {/* ======= Navigation ======= */}
        <div className="navigation">
          <nav>
            {/* About */}
            <ul
              className={`mainMenu ${webSize < 991 ? "mobMenu" : " webMenu"}`}
            >
              <p className= "navTitle">Menu</p>
              {navBarArray.map((res, i) => {
                return (
                  <>
                    <Navigator
                      name={res?.name}
                      path={res?.path}
                      navIcon={res?.navIcon}
                      navSVG={res?.navSVG}
                      onClick={res?.onClick && res?.onClick}
                    >
                      {res?.subMenu && (
                        <ul className={`subMenu`}>
                          <span className="subhead">{res?.name}</span>
                          {res?.subMenu?.map((subMenu) => {
                            return (
                              <Navigator
                                name={subMenu.subName}
                                path={subMenu.subPath}
                                onClick={subMenu.onClick}
                              />
                            );
                          })}
                        </ul>
                      )}
                    </Navigator>
                  </>
                );
              })}

              <p className= "navTitle">User</p>
              {array2.map((res, i) => {
                return (
                  <>
                    <Navigator
                      name={res?.name}
                      path={res?.path}
                      navIcon={res?.navIcon}
                      onClick={res?.onClick && res?.onClick}
                    >
                      {res?.subMenu && (
                        <ul className={`subMenu`}>
                          <span className="subhead">{res?.name}</span>
                          {res?.subMenu?.map((subMenu) => {
                            return (
                              <Navigator
                                name={subMenu.subName}
                                path={subMenu.subPath}
                                onClick={subMenu.onClick}
                              />
                            );
                          })}
                        </ul>
                      )}
                    </Navigator>
                  </>
                );
              })}

              <p className= "navTitle">Services</p>
              {array3.map((res, i) => {
                return (
                  <>
                    <Navigator
                      name={res?.name}
                      path={res?.path}
                      navIcon={res?.navIcon}
                      onClick={res?.onClick && res?.onClick}
                    >
                      {res?.subMenu && (
                        <ul className={`subMenu`}>
                          <span className="subhead">{res?.name}</span>
                          {res?.subMenu?.map((subMenu) => {
                            return (
                              <Navigator
                                name={subMenu.subName}
                                path={subMenu.subPath}
                                onClick={subMenu.onClick}
                              />
                            );
                          })}
                        </ul>
                      )}
                    </Navigator>
                  </>
                );
              })}
                <p className= "navTitle">Bookings</p>
              {array6.map((res, i) => {
                return (
                  <>
                    <Navigator
                      name={res?.name}
                      path={res?.path}
                      navIcon={res?.navIcon}
                      onClick={res?.onClick && res?.onClick}
                    >
                      {res?.subMenu && (
                        <ul className={`subMenu`}>
                          <span className="subhead">{res?.name}</span>
                          {res?.subMenu?.map((subMenu) => {
                            return (
                              <Navigator
                                name={subMenu.subName}
                                path={subMenu.subPath}
                                onClick={subMenu.onClick}
                              />
                            );
                          })}
                        </ul>
                      )}
                    </Navigator>
                  </>
                );
              })}
              <p className= "navTitle">Finance</p>
              {array4.map((res, i) => {
                return (
                  <>
                    <Navigator
                      name={res?.name}
                      path={res?.path}
                      navIcon={res?.navIcon}
                      onClick={res?.onClick && res?.onClick}
                    >
                      {res?.subMenu && (
                        <ul className={`subMenu`}>
                          <span className="subhead">{res?.name}</span>
                          {res?.subMenu?.map((subMenu) => {
                            return (
                              <Navigator
                                name={subMenu.subName}
                                path={subMenu.subPath}
                                onClick={subMenu.onClick}
                              />
                            );
                          })}
                        </ul>
                      )}
                    </Navigator>
                  </>
                );
              })}
              <p className= "navTitle">Report</p>
              {array5.map((res, i) => {
                return (
                  <>
                    <Navigator
                      name={res?.name}
                      path={res?.path}
                      navIcon={res?.navIcon}
                      onClick={res?.onClick && res?.onClick}
                    >
                      {res?.subMenu && (
                        <ul className={`subMenu`}>
                          <span className="subhead">{res?.name}</span>
                          {res?.subMenu?.map((subMenu) => {
                            return (
                              <Navigator
                                name={subMenu.subName}
                                path={subMenu.subPath}
                                onClick={subMenu.onClick}
                              />
                            );
                          })}
                        </ul>
                      )}
                    </Navigator>
                  </>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

export const SideMenuJS = () => {
  const [menu, setMenu] = useState(false);
  useEffect(() => {
    $(".subMenu").hide();

    // ============== sidemenu toggle ==================
    const handleNav = (event) => {
      const target = event.currentTarget;
      $(".subMenu").not($(target).next(".subMenu")).slideUp();
      $(".mainMenu i").not($(target).children("i")).removeClass("rotate90");
      $(target).next(".subMenu").slideToggle();
      $(target).children("i").toggleClass("rotate90");
    };
    $(".mainMenu.webMenu > li > a").on("click", handleNav);

    // ============== sidebar toggle ==================
    const handleSidebar = () => {
      // Sidemenu Off In Mobile Menu
      $(".subMenu").slideUp();
      $(".mainMenu i").removeClass("rotate90");
      // Mobile Menu Class
      $(".mainAdminGrid").toggleClass("webAdminGrid");
      $(".mainMenu").toggleClass("mobMenu webMenu");
      setMenu(menu ? false : true);
    };
    $(".navToggle").on("click", handleSidebar);

    return () => {
      $(".mainMenu > li > a").off("click", handleNav);
      $(".navToggle").off("click", handleSidebar);
    };
  }, [menu]);
  return null;
};
