/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import Table from "../../extras/Table";
import Button from "../../extras/Button";
import Pagination from "../../extras/Pagination";
import Title from "../../extras/Title";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  expertDelete,
  getAllExpert,
  blockExpert,
} from "../../../redux/slice/expertSlice";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import { ExpertDialogue } from "./ExpertDialogue";
import {  warning } from "../../utils/Alert";
import ToggleSwitch from "../../extras/ToggleSwitch";
// import { Dummy } from "./Dummy";
import { useNavigate } from "react-router-dom";
import Searching from "../../extras/Searching";
import Male from "../../../assets/images/male.png";
import NotificationDialog from "../User/NotificationDialog";

// import {
//   useQuery,useQueryClient
// } from '@tanstack/react-query'
// import { fetchExpertData } from "../../../redux/api";
export const Expert = () => {
  // const dummyExperts = Dummy(10000);
  const [data, setData] = useState([]);

  const { expert, total } = useSelector((state) => state.expert);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  ;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // useEffect(() =>{
  //   setData(Dummy(100000))
  // },[])
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  useEffect(() => {
    const payload = {
      start: page,
      limit: rowsPerPage,
      search,
    };
    dispatch(getAllExpert(payload));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    setData(expert);
  }, [expert]);

  //   const { data: expertData, isError, refetch } = useQuery({
  //     queryKey: ['expertData', page, rowsPerPage, search],
  //     queryFn: () => fetchExpertData({ start: page, limit: rowsPerPage, search }),
  //     config: {
  //       refetchOnWindowFocus: false,
  //       onSuccess: (data) => {
  //         setData(data?.experts);
  //       },
  //     },
  //   });
  //  const [total,setTotal] = useState()
  //   useEffect(() => {
  //     if (expertData) {
  //       setData(expertData.experts);
  //       setTotal(expertData.total)
  //     }
  //   }, [expertData]);

  function openImage(imageUrl) {
    // Open the image in a new tab or window
    window.open(imageUrl, "_blank");
  }

  const handleInfo = (id) => {
    
    navigate("/admin/expert/getExpertProfile", {
      state: {
        id,
      },
    });
  };

  const expertTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Image",
      Cell: ({ row }) => (
        <div
          className="userProfile"
          style={{ height: "70px", width: "70px", overflow: "hidden" }}
        >
          <img
            src={row && row.image ? row.image : Male}
            alt="image"
            className="cursor-pointer"
            onClick={() => openImage(row && row.image)}
            height={`100%`}
            onError={(e) => {
              e.target.src = Male;
            }}
          />
        </div>
      ),
    },
    {
      Header: "Name",
      Cell: ({ row }) => (
        <span
          className="text-capitalize fw-bold cursor"
          onClick={() => handleInfo(row._id)}
        >
          {row?.fname ? row?.fname + " " + row?.lname : "Salon User"}
        </span>
      ),
    },
    {
      Header: "Email",
      Cell: ({ row }) => <span>{row?.email}</span>,
    },
    {
      Header: "Mobile No",
      Cell: ({ row }) => <span>{row?.mobile}</span>,
    },
    {
      Header: "Age",
      body: "age",
      Cell: ({ row }) => <span>{row?.age}</span>,
      sorting: { type: "client" },
    },
    {
      Header: "Gender",
      Cell: ({ row }) => <span className="text-capitalize">{row?.gender}</span>,
    },
    {
      Header: "Commission",
      body: "commission",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.commission + " %"}</span>
      ),
    },
    {
      Header: "Rating",
      body: "averageRating",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <span>{row?.averageRating ? row?.averageRating?.toFixed(1) : "-"}</span>
      ),
    },
    {
      Header: "Payment Type",
      Cell: ({ row }) => <span>{row?.paymentType == 0 ? "UPI" : "Bank"}</span>,
    },
    {
      Header: "Block",
      body: "isBlock",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row?.isBlock}
          onClick={() => handleBlock(row?._id)}
        />
      ),
    },
    {
      Header: "Info",
      Cell: ({ row }) => (
        <span>
          <button
            className="bg-theme text-light m5-right p12-x p4-y fs-12 br-5 "
            onClick={() => handleInfo(row?._id)}
          >
            Info
          </button>
          <button
            className="bg-primary rounded-1 text-light m5-right p10-x p4-y fs-12"
            onClick={() => handleEarning(row?._id)}
          >
            History
          </button>
        </span>
      ),
      width: "50px",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <button
            className="bg-success text-light m5-right p10-x p4-y fs-12 br-5"
            onClick={() => handleOpenDialog(row)}
          >
            Edit
          </button>
          <button
            className="bg-danger text-light p10-x p4-y fs-12 br-5"
            onClick={() => handleDelete(row?._id)}
          >
            DELETE
          </button>
        </span>
      ),
      width: "200px",
    },
    {
      Header: "Notification",
      Cell: ({ row }) => (
        <span className="d-flex g-2">
          <button
            className="bg-theme rounded-1 text-light fs-13 m5-right p20-x p4-y fs-12"
            onClick={() => handleNotify(row?._id)}
          >
            <i class="fa-regular fa-bell" />
          </button>
        </span>
      ),
    },
  ];

  const handleOpenDialog = (row) => {
   
      dispatch(openDialog({ type: "expert", data: row }));
 
  };

  const handleBlock = (id) => {
    
 
      dispatch(blockExpert(id));
  
  };

  const handleEarning = (id) => {
   
      navigate("/admin/expert/income", {
        state: {
          id,
        },
      });
    
  };

  const handleNotify = (id) => {
    
      dispatch(
        openDialog({ type: "notification", data: { id, type: "expert" } })
      );
    
  }

  // const queryClient = useQueryClient();
  const handleDelete = async (id) => {
    
   
      try {
        const data = await warning("Delete");
        const yes = data.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(expertDelete(id));
          // await  queryClient.invalidateQueries({ queryKey: ['expertData'] });
        }
      } catch (err) {
        console.log(err);
      }
    
  };

  return (
    <div className="mainExpert">
      <Title name="Experts" />
      {dialogue && dialogueType === "notification" && (
        <div className="userTable">
          <NotificationDialog />
        </div>
      )}
      <div className="betBox">
        <Button
          className={`bg-button p-10 text-white m10-bottom`}
          text={`Add Expert`}
          bIcon={`fa-solid fa-user-plus`}
          onClick={() => {
            dispatch(openDialog({ type: "expert" }));
          }}
        />

        <div className="col-md-8 col-lg-5  ms-auto">
          <Searching
            type={`server`}
            data={data}
            setData={setData}
            column={expertTable}
            serverSearching={handleFilterData}
          />
        </div>
      </div>
      <div>
        <Table
          data={data}
          mapData={expertTable}
          serverPerPage={rowsPerPage}
          // PerPage={rowsPerPage}
          Page={page}
          // type={"server"}
        />
        <Pagination
          type={"server"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={total}
        />
      </div>
      {dialogue && dialogueType === "expert" && (
        <ExpertDialogue setData={setData} data={data} />
      )}
    </div>
  );
};
