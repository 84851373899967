/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */

import { openDialog } from "../../redux/slice/dialogueSlice";
import { getPayout } from "../../redux/slice/payoutSlice";
import {  warning } from "../utils/Alert";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Title from "../extras/Title";
import Button from "../extras/Button";
import Table from "../extras/Table";
import Pagination from "../extras/Pagination";

import { getSalary, payment } from "../../redux/slice/salarySlice";
import BonusPenaltyDialog from "./BonusPenaltyDialog";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Payout = () => {
  const { salary } = useSelector((state) => state.salary);
  const [data, setData] = useState([]);

  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
   
  const { setting } = useSelector((state) => state.setting);


  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [type, setType] = useState("ALL");
  const navigate = useNavigate();
  const thisMonth = new Date();
  thisMonth.setDate(1); 
  const [selectedDate, setSelectedDate] = useState(thisMonth);
  const dispatch = useDispatch();
  
  useEffect(() => {
    const formattedDate = moment(selectedDate, 'YYYY-MM').format('YYYY-MM');
    const payload = {
      start: page,
      limit: rowsPerPage,
      month: formattedDate,
      type
    };
    dispatch(getSalary(payload));
  }, [page, rowsPerPage, selectedDate,type,payment]);

  useEffect(() => {
    setData(salary);
  }, [salary]);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  function openHistory(id) {
    navigate("/admin/expert/income", {
      state: {
        id
      }
    })
  }

  const mapData = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },

    {
      Header: "Image",
      Cell: ({ row }) => (
        <div
          className="userProfile"
          style={{ height: "70px", width: "70px", overflow: "hidden" }}
        >
          <img
            src={row?.expertId?.image}
            alt="image"
            className="cursor-pointer"
            onClick={() => openHistory(row?.expertId?._id)}
            height={`100%`}
            
          />
        </div>
      ),
    },
    {
      Header: "Month",
      Cell: ({ row }) => (
        <span className="text-capitalize fw-bold "  >
          {row?._id?.month}
        </span>
      ),
    },
    {
      Header: "Expert",
      Cell: ({ row }) => (
        <span className="text-capitalize fw-bold cursor"  onClick={() => openHistory(row?.expertId?._id)}>
          {row?.expertId?.fname + " " + row?.expertId?.lname}
        </span>
      ),
    },
    {
      Header: "Total Bookings",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.totalExpertEarning == 0 ? 0 : row?.count}</span>
      ),
    },
    {
      Header: "Total (Earnings)",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.totalExpertEarning + " " + setting?.currencySymbol}</span>
      ),
    },
    {
      Header: "Admin Earnings",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.totalAdminEarning + " " + setting?.currencySymbol}</span>
      ),
    },
    
    {
      Header: "Note",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.bonus ? row?.bonus?.note : "-"}</span>
      ),
    },
    {
      Header: "Bonus/Penalty",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.bonus?.bonus_penalty  + " " + setting?.currencySymbol}
        </span>
      ),
    },
    {
      Header: "Final Amount",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.totalExpertEarning + row?.bonus?.bonus_penalty  + " " + setting?.currencySymbol}
        </span>
      ),
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          {row?.statusOfTransaction === 1 ? (
            <button
              className="bg-theme text-light m5-right p10-x p4-y fs-12 br-5"
              onClick={() =>
                dispatch(openDialog({ type: "bonus", data: row._id }))
              }
            >
              Action
            </button>
          ) : null}
          {row?.statusOfTransaction === 1 && (
            <button
              className="bg-danger text-light p10-x p4-y fs-12 br-5"
              onClick={() => handlePayment(row)}
            >
              Pay
            </button>
          )}
          {row?.statusOfTransaction === 2 && (
            <button className="bg-success text-light p10-x p4-y fs-12 br-5">
              Paid
            </button>
          )}
        </span>
      ),
      width: "300px",
    },
  ];

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePayment = (row) => {
    
    const ids = row?.originalIds ? row?.originalIds?.join(',') : "";
    const payload = {
      expertId: row.expertId._id,
      month: row.month,
      data: {
        amount: row?.totalExpertEarning + row?.bonus?.bonus_penalty,
        bonus_penalty: row?.bonus?.bonus_penalty,
        serviceAmount: row?.totalExpertEarning,
        month: row?.month,
        expertId: row?.expertId?._id,
        paymentMethod: row?.expertId?.paymentType,
        note: row?.bonus?.note,
        settlementId: ids,
        bookings: row?.totalExpertEarning == 0 ? 0 : row?.count
      }
    }
    dispatch(payment(payload));
  };

  const types = [
    { name: "Pending", value: 'unpaid' },
    { name: "Paid", value: 'paid' },
  ];

  return (
    <div className="mainCategory">
      {dialogue && dialogueType === "bonus" && <BonusPenaltyDialog />}
      <Title name="Expert Payment" />
      <div className="d-flex">

        <div className="m40-bottom inputData col-lg-2 col-md-4 me-3">
          <label>Select Month</label>
          {/* <input
            type="month"
            value={selectedDate}
            onChange={(e) => handleDateChange(e.target.value)}
          /> */}
        {/* <input type="month" value={selectedDate} onChange={(e) => handleDateChange(e.target.value)} /> */}
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="yyyy/MM"
          showMonthYearPicker
        />
        </div>
        <div className="col-md-3 col-lg-2">
          <div className="inputData">
            <label className="styleForTitle" htmlFor="settlement">
              Settlement Type
            </label>
            <select
              name="types"
              className="rounded-2"
              id="bookingType"
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              <option value="ALL" selected>
                ALL
              </option>
              {types?.map((data) => {
                return <option value={data?.value}>{data?.name}</option>;
              })}
            </select>
          </div>
        </div>
      </div>
      <div>
        <Table
          data={data}
          mapData={mapData}
          PerPage={rowsPerPage}
          Page={page}
          type={"client"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={data?.length}
        />
      </div>
    </div>
  );
};

export default Payout;
