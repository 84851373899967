/* eslint-disable eqeqeq */
import {

  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import $ from "jquery";
import { useEffect } from "react";
import DashBoard from "../tables/Dashboard/DashBoard"
import Booking from "../tables/booking/Booking";
import { AdminProfile } from "./AdminProfile";
import { Expert } from "../tables/expert/Expert";
import Category from "../tables/category/Category";
import SubCategory from "../tables/subCategory/SubCategory";
import Service from "../tables/services/Service";
import { Review } from "../tables/review/Review";
import Tax from "../tables/tax/Tax";
import { User } from "../tables/User/User";
import Setting from "../tables/setting/Setting";
import ExpertProfile from "../tables/expert/ExpertProfile";
import WeekTime from "../tables/timeSlot/WeekTime";
import { useDispatch, useSelector } from "react-redux";
import UpcomingBooking from "../tables/booking/UpcomingBooking";
import StaffEarning from "../tables/StaffEarning";
import Payout from "../tables/Payout";
import Attendance from "../tables/Attendance";
import AttendanceTable from "../tables/AttendanceTable";
import UserProfile from "../tables/User/UserProfile";
import DailyBooking from "../tables/booking/DailyBooking";
import ExpertHistory from "../tables/expert/ExpertHistory";
import MonthlyReport from "../tables/MonthlyReport";
import Banner from "../tables/banner/Banner";
import Complain from "../tables/complain/Complain";
import ExpertIncome from "../tables/expert/ExpertIncome";
import AllPaymentHistory from "../tables/payment/AllPaymentHistory";
import ExpertPaymentHistory from "../tables/payment/ExpertWiseHistory";
import UserHistory from "../tables/User/UserHistory";
import YearlyPayment from "../tables/payment/YearlyPayment";
import Holiday from "../tables/timeSlot/Holiday";
import CalendarPage from "../tables/booking/CalendarPage";

// import PrivateRoute from "../util/PrivateRoute";
// import "../../assets/js/custom";
// import CoinPlan from "../Table/coinPlan/CoinPlan";
// import PurchaseHistory from "../Table/coinPlan/PurchaseHistory";

const Admin = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { admn } = useSelector((state) => state.auth);



  // useEffect(() => {
  //   if (["/", "/admin", "/admin/", ""].includes(location.pathname)) {
  //     navigate("/admin/dashboard");
  //   }
  // }, []);


  useEffect(() => {
    if (
      location.pathname == "/" ||
      location.pathname == "/admin" ||
      location.pathname == "/admin/" ||
      location.pathname == ""
    ) {
      // window.location.reload(true);
      navigate("/admin/adminDashboard");

    }
  }, []);
  var webSize = $(window).width();

  return (
    <div className={`mainAdminGrid  ${webSize < 991 && "webAdminGrid"}`}>
      <Sidebar />

      <div className={`mainAdmin`}>
        <Navbar />
        <div className="adminStart">
          <Routes>
            <Route path="/adminDashboard" element={<DashBoard />} />
            <Route path="/bookingTable" element={<Booking />} />
            <Route path="/profile" element={<AdminProfile />} />
            <Route path="/allExperts" element={<Expert />} />
            <Route path="/categoryTable" element={<Category />} />
            {/* <Route path="/subCategoryTable" element={<SubCategory />} /> */}
            <Route path="/serviceTable" element={<Service />} />
            <Route path="/reviewTable" element={<Review />} />
            <Route path="/tax" element={<Tax />} />
            <Route path="/userTable" element={<User />} />
            <Route path="/calendar" element={<CalendarPage />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/expert/getExpertProfile" element={<ExpertProfile />} />
            <Route path="/timeTable" element={<WeekTime />} />
            <Route path="/staffEarning" element={<StaffEarning />} />
            <Route path="/payout" element={<Payout />} />
            <Route path="/holiday" element={<Holiday />} />
            <Route path="/futureBooking" element={<UpcomingBooking />} />
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/attendanceTable" element={<AttendanceTable />} />
            <Route path="/user/userProfile" element={<UserProfile />} />
            <Route path="/dailyBookingTable" element={<DailyBooking />} />
            <Route path="/expert/history" element={<ExpertHistory />} />
            <Route path="/expert/income" element={<ExpertIncome />} />
            <Route path="/monthlyReport" element={< MonthlyReport />} />
            <Route path="/bannerTable" element={<Banner/>} />
            <Route path="/complainTable" element={<Complain/>} />
            <Route path="/paymentHistory" element={<AllPaymentHistory/>} />
            <Route path="/userHistory" element={<UserHistory/>} />
            <Route path="/expert/paymentHistory" element={<ExpertPaymentHistory />} />
            <Route path="/yearlyPayment" element={<YearlyPayment />} />
            
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Admin;
