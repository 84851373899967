/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import Button from "../../extras/Button";
import Table from "../../extras/Table";
import Pagination from "../../extras/Pagination";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Title from "../../extras/Title";
import { getAllCategory, categoryDelete, categoryStatus } from "../../../redux/slice/categorySlice";
import {  warning } from "../../utils/Alert";


import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import CategoryDialogue from "./CategoryDialogue";

const Category = () => {
    const { category, total } = useSelector((state) => state.category)
    const { dialogue, dialogueType } = useSelector((state) => state.dialogue)
    const [data, setData] = useState([]);
     
    useEffect(() => {
        dispatch(getAllCategory())
    }, [])

    useEffect(() => {
        setData(category);
    }, [category]);

    const dispatch = useDispatch();

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event, 10));
        setPage(0);
    };

    const [search, setSearch] = useState("");

    const handleFilterData = (filteredData) => {
        if (typeof filteredData === "string") {
            setSearch(filteredData);
        } else {
            setData(filteredData);
        }
    };

    function openImage(imageUrl) {
        // Open the image in a new tab or window
        window.open(imageUrl, "_blank");
    }

      // Only Server 00000000000000000000000000000000Sorthing
  const [type, setType] = useState(0);
  const handleChildValue = (value) => {
    setType(type === 0 ? 1 : 0);
  };

    const categoryTable = [
        {
            Header: "No",
            Cell: ({ index }) => (
                <span>{page * rowsPerPage + parseInt(index) + 1}</span>
            )
        },
        {
            Header: "Image",
            Cell: ({ row }) => (
                <div className="userProfile" style={{ height: "70px", width: "70px", overflow: "hidden" }}>
                    <img src={row?.image} alt="image" className="cursor-pointer" onClick={() => openImage(row?.image)} height={`100%`}  />
                </div>
            ),
        },
        {
            Header: "Name",
            Cell: ({ row }) => (
                <span className="text-capitalize fw-bold">{row?.name}</span>
            ),
        },
        {
            Header: "Created At",
            Cell: ({ row }) => (
                <span className="text-capitalize">{row?.createdAt.split('T')[0]}</span>
            ),
        },
        // {
        //     Header: "Status",
        //     Cell: ({ row }) => (
        //         <ToggleSwitch value={row?.status} onClick={() => { dispatch(categoryStatus(row?._id)) }} />
        //     ),
        // },
        {
            Header: "Action",
            Cell: ({ row }) => (
                <span>
                    <button
                        className='bg-success text-light m5-right p10-x p4-y fs-12 br-5'
                        onClick={() => dispatch(openDialog({ type: "category", data: row }))}
                    >Edit</button>
                    <button className='bg-danger text-light p10-x p4-y fs-12 br-5' onClick={() => handleDelete(row?._id)}>DELETE</button>
                </span>
            ),
            width: "300px"
        }
    ];


    const handleDelete = (id) => {
        
        const data = warning("Delete");
        data
            .then((logouts) => {
                const yes = logouts.isConfirmed
                console.log("yes", yes);
                if (yes) {
                    dispatch(categoryDelete(id))
                }
            })
            .catch((err) => console.log(err));
    }
    return (
        <div className="mainCategory">
            <Title name="Category" />
            <Button
                className={`bg-button p-10 text-white m10-bottom`}
                text={`Add Category`}
                bIcon={`fa-solid fa-user-plus`}
                onClick={() => {
                    dispatch(openDialog({ type: "category" }));
                }}
            />
            <div>
                <Table
                    data={data}
                    mapData={categoryTable}
                    PerPage={rowsPerPage}
                    Page={page}
                    type={"client"}
                // onChildValue={handleChildValue}
                />
                <Pagination
                    type={"client"}
                    serverPage={page}
                    setServerPage={setPage}
                    serverPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    totalData={data?.length}
                />
            </div>
            {
                dialogue && dialogueType === "category" && (
                    <CategoryDialogue setData={setData} data={data} />
                )
            }
        </div>
    )
};

export default Category
