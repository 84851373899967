/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import Button from "../../extras/Button";
import Table from "../../extras/Table";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Title from "../../extras/Title";
import {  warning } from "../../utils/Alert";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBanner,
  getBanners,
  isActive,
} from "../../../redux/slice/bannerSlice";
import BannerDialog from "./BannerDialog";
import male from "../../../assets/images/male.png";

const Banner = () => {
  const banner = useSelector((state) => state.banner.banner);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const [data, setData] = useState([]);
   

  useEffect(() => {
    dispatch(getBanners());
  }, []);

  useEffect(() => {
    setData(banner);
  }, [banner]);

  const dispatch = useDispatch();

  function openImage(imageUrl) {
    // Open the image in a new tab or window
    window.open(imageUrl, "_blank");
  }

  const bannerTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Image",
      Cell: ({ row }) => (
        <div
          className="userProfile"
          style={{ height: "70px", width: "70px", overflow: "hidden" }}
        >
          <img
            src={row?.image}
            alt="image"
            className="cursor-pointer"
            onClick={() => openImage(row?.image)}
            
            onError={(e) => {
              e.target.src = male;
            }}
            height={`100%`}
          />
        </div>
      ),
    },
    {
      Header: "Type",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.type === 0 ? "ServiceId" : "Url"}</span>
      ),
    },
    {
      Header: "Type Details",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.type === 0? row?.categoryId?.name : row?.url ?  row?.url : '-'}</span>
      ),
    },
    {
      Header: "Created At",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.createdAt?.split("T")[0]}</span>
      ),
    },
    {
      Header: "Status",
      thClass:"upIndex",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row?.isActive}
          onClick={() => {
            
            dispatch(isActive(row?._id));
          }}
        />
      ),
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          {/* <button
            className="bg-success text-light m5-right p10-x p4-y fs-12 br-5"
            onClick={() => dispatch(openDialog({ type: "banner", data: row }))}
          >
            Edit
          </button> */}
          <button
            className="bg-danger text-light p10-x p4-y fs-12 br-5"
            onClick={() => handleDelete(row?._id)}
          >
            DELETE
          </button>
        </span>
      ),
      width: "300px",
    },
  ];

  const handleDelete = (id) => {
    
    const data = warning("Delete");
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(deleteBanner(id));
        }
      })
      .catch((err) => console.log(err));
    };
    
  return (
    <div className="mainCategory">
      <Title name="Banner" />
      <Button
        className={`bg-button p-10 text-white m10-bottom`}
        text={`Add Banner`}
        bIcon={`fa-solid fa-user-plus`}
        onClick={() => {
          dispatch(openDialog({ type: "banner" }));
        }}
      />
      <div>
        <Table data={data} mapData={bannerTable} />
      </div>
      {dialogue && dialogueType === "banner" && (
        <BannerDialog setData={setData} data={data} />
      )}
    </div>
  );
};

export default Banner;
